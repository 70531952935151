import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum LoginPageState {
  PhoneNumber,
  EnterOtp,
  VerifyOtp,
  VerifyLoginOtp,
  SetPin,
  EnterPin,
  Verified,
  EnterLoginOtp,
  PinUpdated
}


@Injectable({
  providedIn: 'root'
})
export class LoginSignupService {
  private currentState = new BehaviorSubject<LoginPageState>(
    LoginPageState.PhoneNumber
  );
  constructor() { }

  getCurrentState(): Observable<LoginPageState> {
    return this.currentState.asObservable();
  }

  setCurrentState(state: LoginPageState): void {
    this.currentState.next(state);
  }
}
